import { useTranslation } from 'react-i18next';
import { HttpClient, TApiError } from '@aviobook/_http';
import { queryClient } from '@aviobook/index';
import { toastError, toastSuccess } from '@aviobook/Toastify';
import { useMutation } from '@tanstack/react-query';
import { TRole } from 'types';

function role(values: TRole): Promise<TRole> {
  return HttpClient.patch(`/api/v1/admin/role/${values.id}`, {
    isTaggable: values.isTaggable,
    permissions: values.permissions,
    tags: values.tags,
  });
}

export function useUpdateRole(onSuccess?: () => void) {
  const { t } = useTranslation();

  return useMutation<TRole, TApiError, TRole>(['role'], role, {
    onError: () => {
      toastError(t('TOAST_ERROR'));
    },
    onSuccess: data => {
      queryClient.invalidateQueries(['authenticate']);
      queryClient.invalidateQueries(['roles']);
      queryClient.invalidateQueries(['role', data.id]);
      toastSuccess(t('TOAST_SUCCESS'));
      if (typeof onSuccess === 'function') {
        onSuccess();
      }
    },
  });
}
