import { useTranslation } from 'react-i18next';
import { HttpClient, TApiError } from '@aviobook/_http';
import { queryClient } from '@aviobook/index';
import { toastError, toastSuccess } from '@aviobook/Toastify';
import { useMutation } from '@tanstack/react-query';
import { TChatbot } from 'types/src/chatbot';

function chatbots(values: TChatbot): Promise<TChatbot> {
  const keywords = values.keywords.map(keyword => ({
    description: keyword.description,
    keyword: keyword.keyword,
  }));
  return HttpClient.patch(`/api/v1/admin/chatbot/${values.id}`, {
    apiKey: values.apiKey,
    description: values.description,
    keywords,
    name: values.name,
    url: values.url,
  });
}

export function useUpdateChatbot({ onSuccess }) {
  const { t } = useTranslation();
  return useMutation<TChatbot, TApiError, TChatbot>(['chatbots'], chatbots, {
    onError: () => {
      toastError(t('TOAST_ERROR'));
    },
    onSuccess: data => {
      queryClient.invalidateQueries(['chatbots']);
      queryClient.invalidateQueries(['chatbot', data.id]);
      toastSuccess(t('TOAST_SUCCESS'));
      onSuccess();
    },
  });
}
