import { useGetOrganizationSettings } from '@aviobook/cms/connect/_screens/_queries/useGetOrganizationSettings';
import { useUpdateOrganizationSettings } from '@aviobook/cms/connect/_screens/_queries/useUpdateOrganizationSettings';

export function useChatbotConfiguration() {
  const { data, isLoading: isLoadingSettings } = useGetOrganizationSettings();
  const { mutateAsync, isLoading: isLoadingMutation } = useUpdateOrganizationSettings();

  const isLoading = isLoadingSettings || isLoadingMutation;

  return {
    data,
    isLoading,
    update: async (showUserNames: boolean) =>
      await mutateAsync({
        ...data,
        showUserNames,
      }),
  };
}
