import { FC, useEffect, useState } from 'react';
import { Text } from '@aviobook/web/_shared';

import './tags.scss';

type TProps = {
  onPressTag: (tag: string) => void;
  tags: string[];
};

export const Tags: FC<TProps> = ({ onPressTag, tags }) => {
  const [visibleTags, setVisibleTags] = useState<string[]>([]);

  // Calculate the number of tags that can fit on the screen
  const MAX_TAG_CHARACTERS = 100; // Adjust this value as needed
  const { TAG_LIMIT } = tags.reduce(
    (acc, tag) => {
      const totalChars = acc.totalChars + tag.length;
      return totalChars <= MAX_TAG_CHARACTERS ? { TAG_LIMIT: acc.TAG_LIMIT + 1, totalChars } : acc;
    },
    { TAG_LIMIT: 0, totalChars: 0 },
  );

  useEffect(() => {
    if (tags.length > TAG_LIMIT) {
      setVisibleTags([...tags.slice(0, TAG_LIMIT), `+${tags.length - TAG_LIMIT}`]);
    } else {
      setVisibleTags(tags);
    }
  }, [tags, TAG_LIMIT]);

  const handlePressTag = (tag: string) => {
    if (tag === `+${tags.length - TAG_LIMIT}`) {
      setVisibleTags(tags);
    } else {
      onPressTag(tag);
    }
  };

  return (
    <div className="tags-container">
      {visibleTags.map((tag, index) => (
        <button className="tags-button" key={tag + index} onClick={() => handlePressTag(tag)}>
          <Text as="span" color="coreWhite100" size="SM">
            {tag}
          </Text>
        </button>
      ))}
    </div>
  );
};
