import { HttpClient, TApiError } from '@aviobook/_http';
import { MutationOptions, useMutation } from '@tanstack/react-query';

export function acceptAgreement(): Promise<void> {
  return HttpClient.post('/api/v1/users/accept-agreement', { hasAccepted: true });
}

export function useAcceptAgreement(options?: MutationOptions<void, TApiError>) {
  return useMutation<void, TApiError>(['acceptAgreement'], acceptAgreement, options);
}
