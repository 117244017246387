import React, { useEffect } from 'react';
import { personalInfoSocket } from '@aviobook/_utils/socket';
import { Icon, Spinner, Text, UnreadDot } from '@aviobook/web/_shared';
import { ModalOpener } from '@aviobook/web/modal/ModalOpener';
import { UserRequestAccessModal } from '@aviobook/web/modal/userRequestAccessModal/UserRequestAccessModal';
import { getFlightroomAirportNames } from 'shared';
import { TFlightroom, TRequest } from 'types';

import { useGetRequests } from '../../_queries/useGetRequests';

import './chatHeader.scss';

type TProps = {
  flightroom: TFlightroom;
  onClickInfo: (isOpen: boolean) => void;
};

export const ChatHeader: React.FC<TProps> = ({ onClickInfo, flightroom }) => {
  const [requests, setRequests] = React.useState<TRequest[]>([]);
  const { refetch: refetchRequests, isRefetching: isRefetchingRequests } = useGetRequests(flightroom.id, {
    onSuccess: setRequests,
  });

  useEffect(() => {
    personalInfoSocket.on(`access-request-${flightroom.id}`, request => {
      setRequests(prevRequests => [...prevRequests, request]);
    });
    return () => {
      personalInfoSocket.off(`access-request-${flightroom.id}`);
    };
  }, [flightroom.id]);

  const { departureAirport, arrivalAirport } = getFlightroomAirportNames(flightroom);

  const onClickRequestIcon = () => {
    ModalOpener.instance.open({
      render: () => (
        <UserRequestAccessModal
          flightroomName={flightroom.name}
          onSuccess={() => {
            refetchRequests();
            ModalOpener.instance.close();
          }}
          requests={requests}
          roomId={flightroom.id}
        />
      ),
    });
  };

  return (
    <header className="chat-header-container">
      <section className="chat-header-left">{/* <StatusPill label="on time" /> */}</section>
      <section className="chat-header-middle">
        <Text as="span" className="chat-header-title" color="aviobookLightPrimaryAlpha" size="LG" weight="bold">
          {flightroom.name}
        </Text>
        <Text as="span" color="aviobookWebPrimaryZulu04" size="XXS" weight="semiBold">
          {departureAirport} &rarr; {arrivalAirport}
        </Text>
      </section>
      <section className="chat-header-right">
        {isRefetchingRequests ? (
          <Spinner />
        ) : (
          requests?.length > 0 && (
            <button className="chat-header-notification-icon" onClick={onClickRequestIcon}>
              <UnreadDot amount={requests.length} />
              <Icon name="peopleUserManagementUserCircularPlus" size={2.5} />
            </button>
          )
        )}
        <Icon
          name="circularInfoOutline"
          onClick={() => {
            if (flightroom.id) {
              onClickInfo(true);
            }
          }}
          size={2.5}
        />
      </section>
    </header>
  );
};
