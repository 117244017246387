import { HttpClient, TApiError } from '@aviobook/_http';
import { queryClient } from '@aviobook/index';
import { useMutation } from '@tanstack/react-query';

function updateLastInteraction(roomId: string): Promise<void> {
  return HttpClient.post(`/api/v1/rooms/last-interaction/${roomId}`);
}

export function useUpdateLastInteraction() {
  return useMutation<void, TApiError, string>(['updateLastInteraction'], updateLastInteraction, {
    onSuccess: async () => {
      await queryClient.invalidateQueries(['getFlightrooms']);
    },
  });
}
