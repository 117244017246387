import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Checkbox, PageContentBlock, SaveButton, Spinner, Text } from '@aviobook/cms/_shared';

import { useChatbotConfiguration } from './_hooks/useChatbotConfiguration';

import './chatbotConfiguration.scss';

export const ChatbotConfiguration = () => {
  const { t } = useTranslation();
  const { data, isLoading, update } = useChatbotConfiguration();
  const [checked, setChecked] = useState<boolean>(undefined);

  if (isLoading) {
    return <Spinner>{t('SHARED.LOADING_APPLICATION')}</Spinner>;
  }

  const isDisabled = checked === undefined || checked === data?.showUserNames;

  return (
    <>
      <div className="role-form-top-container">
        <SaveButton isDisabled={isDisabled} onClick={() => update(checked)} />
      </div>
      <PageContentBlock title={t('CHATBOT.PRIVACY.CONFIGURATION.TITLE')}>
        <Text className="text-wrapper" color="aviobookLightPrimaryAlpha" size="MD" uppercase weight="bold">
          {t('CHATBOT.PRIVACY.CONFIGURATION.TEXT')}
        </Text>
        <Checkbox
          checked={checked ?? data?.showUserNames}
          className="checkbow-wrapper"
          name="configuration"
          onChange={setChecked}
        />
      </PageContentBlock>
    </>
  );
};
