import { HttpClient, TApiError } from '@aviobook/_http';
import { useQuery } from '@tanstack/react-query';
import { TFlightroom } from 'types';

async function getFlightroom(roomId: string): Promise<TFlightroom> {
  return HttpClient.get<TFlightroom>(`/api/v1/rooms/${roomId}?roomId=${roomId}`);
}

export function useGetFlightroom(roomId: string) {
  return useQuery<TFlightroom, TApiError>(['getFlightroom', roomId], () => getFlightroom(roomId), { enabled: !!roomId });
}
