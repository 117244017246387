import { useTranslation } from 'react-i18next';
import { Text } from '@aviobook/web/_shared';
import { TGiftedMessage } from 'shared';

export const UserInfo = (props: { user: TGiftedMessage['user'] }) => {
  const { user } = props;
  const { t } = useTranslation();

  if (!user?._id)
    return (
      <div className="message-bubble-avatar-name">
        <Text color="aviobookLightPrimaryAlpha" size="SM" uppercase weight="bold">
          {t('USER.UNKNOWN')}
        </Text>
      </div>
    );

  return (
    <div className="message-bubble-avatar-name">
      <Text color="aviobookLightPrimaryAlpha" size="SM" uppercase weight="bold">
        {user?.jobTitle}
      </Text>
      <Text color="aviobookWebPrimaryZulu04" size="XS" weight="semiBold">
        {` - ${user?.name}`}
      </Text>
    </div>
  );
};
