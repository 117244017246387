import { useTranslation } from 'react-i18next';
import { HttpClient } from '@aviobook/_http';
import { queryClient } from '@aviobook/index';
import { toastError, toastSuccess } from '@aviobook/Toastify';
import { useMutation } from '@tanstack/react-query';
import { TChatbot } from 'types/src/chatbot';

function deleteChatbot(id: string): Promise<TChatbot> {
  return HttpClient.delete(`/api/v1/admin/chatbot/${id}`);
}

export function useDeleteChatbot() {
  const { t } = useTranslation();
  const mutation = useMutation(['chatbots'], deleteChatbot);

  const deleteChatbots = async (ids: string[]) => {
    const promises = ids.map(id => mutation.mutateAsync(id));
    try {
      return Promise.all(promises);
    } catch (e) {
      toastError(t('TOAST_ERROR'));
    } finally {
      queryClient.invalidateQueries(['chatbots']);
      toastSuccess(t('TOAST_SUCCESS'));
    }
  };

  return { ...mutation, deleteChatbots };
}
