import { FC } from 'react';
import { useAuthenticate } from '@aviobook/web/auth/_queries';
import { useUsersStatusContext } from 'shared';
import { STATUS } from 'types';

import './statusDot.scss';

type TDotSize = 'small' | 'medium' | 'large' | number;

type TProps = {
  size?: TDotSize;
  userId: string;
};

export const StatusDot: FC<TProps> = ({ size, userId }) => {
  const { getStatus } = useUsersStatusContext();
  const onlineIndicator = getStatus(userId);

  const { data: profile } = useAuthenticate();

  const getDotSizeClassName = () => {
    if (typeof size === 'number') {
      return 'custom-size';
    }
    return size || 'default';
  };

  const getDotColorClassName = () => {
    if (userId === profile?.userId) {
      return 'success';
    }
    switch (onlineIndicator) {
      case STATUS.offline:
        return 'warning';
      case STATUS.online:
        return 'success';
      default:
        return 'default';
    }
  };

  const dotSizeClassName = getDotSizeClassName();
  const dotColorClassName = getDotColorClassName();

  const className = `status-dot ${dotSizeClassName} ${dotColorClassName}`;

  return <div className={className} />;
};
