import { FC, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { GiftedChat } from 'react-native-gifted-chat';
import { useAppContext } from '@aviobook/_context/AppContext';
import { generateUUID } from '@aviobook/_utils/generateUUID';
import { chatSocket } from '@aviobook/_utils/socket';
import { useNetworkInfo } from '@aviobook/web/_hooks';
import { Button, SideModal, Spinner } from '@aviobook/web/_shared';
import { GiftedChatMessage } from '@aviobook/web/chat/_components/GiftedChatMessage/GiftedChatMessage';
import { useChatSocket } from '@aviobook/web/chat/_hooks/useChatSocket';
import { FlightroomInfoItem } from '@aviobook/web/flightrooms/_components/flightroomInfoItem/FlightroomInfoItem';
import { useLeaveFlightroom } from '@aviobook/web/flightrooms/_queries/useLeaveFlightrooms';
import { isAfter, parseISO } from 'date-fns';
import { useFlightroomsContext } from 'shared';
import { TCrewMember, TFlightroom, TMessagePayload } from 'types';

import { useAuthenticate } from '../auth/_queries';
import ConfirmationModal from '../modal/confirmation/ConfirmationModal';
import { ChatEmpty, ChatHeader, ImagePreview, InputToolbar } from './_components';

import './chat.scss';

type TProps = {
  room: TFlightroom;
};

export const Chat: FC<TProps> = ({ room }) => {
  const { t } = useTranslation();
  const { setImagePreview, imagePreview } = useAppContext();
  const { openRoom, deleteRoom } = useFlightroomsContext();
  const { isOnline } = useNetworkInfo();
  const { data: profile } = useAuthenticate();
  const { name, id, crew, isScheduledForRoom } = room;

  const [isSideModalOpen, setIsSideModalOpen] = useState(false);
  const [isLeaveRoomModalOpen, setIsLeaveRoomModalOpen] = useState(false);
  const [attachmentPreview, setAttachmentPreview] = useState<string>();

  const {
    mutate: leaveFlightroom,
    isSuccess: isSuccessLeaveFlightroom,
    isLoading: isLoadingLeaveFlightroom,
  } = useLeaveFlightroom();

  const {
    data,
    messages,
    fetchNextPage,
    firstUnreadMessage,
    hasNextPage,
    isFetchingMessages,
    isLoading,
    isRefetching,
    isSuccess,
    lastReadDate,
    mappedMessages,
    socketMessages,
    setFirstUnreadMessage,
    storePendingMessage,
  } = useChatSocket({ roomId: room.id });

  const onSend = async (message: string, attachment?: string) => {
    const payload: TMessagePayload = {
      attachment: attachment ?? null,
      createdDate: new Date(),
      message,
      messageId: generateUUID(),
      roomId: room.id,
      senderInfo: {
        userId: profile.userId,
        username: profile.username,
      },
    };

    if (!isOnline) {
      storePendingMessage(payload);
    } else {
      chatSocket.emit('message', payload);
    }
    setFirstUnreadMessage(undefined);
  };

  const handleScroll = ({ contentOffset, contentSize, layoutMeasurement }) => {
    const isCountZero = data?.pages[0]?.meta.count > 0;
    const totalCount = data?.pages[0]?.meta.totalCount;

    // Calculate the scroll position relative to the content size and layout measurement
    const scrollPosition = contentOffset.y + layoutMeasurement.height;

    // Determine the threshold for fetching more messages (e.g., 100 pixels from the bottom)
    const fetchThreshold = 100;

    // Check if the scroll position is close to the bottom and there are more pages to load,
    // and the totalCount has not been reached
    if (
      contentSize.height - scrollPosition <= fetchThreshold &&
      hasNextPage &&
      !isFetchingMessages &&
      isCountZero &&
      totalCount > messages.length // Check if totalCount is not reached
    ) {
      fetchNextPage();
    }
  };

  useEffect(() => {
    if (isSuccessLeaveFlightroom) {
      setIsLeaveRoomModalOpen(false);
      deleteRoom();
      openRoom();
    }
  }, [isSuccessLeaveFlightroom]);

  useEffect(() => {
    if (isSuccess) {
      const unreadMessages = messages?.filter(message => isAfter(parseISO(message.createdDate), lastReadDate));
      setFirstUnreadMessage(unreadMessages?.[unreadMessages.length - 1]?.messageId);
    }
  }, [lastReadDate, isSuccess]);

  return (
    <div className="chat-container">
      <ChatHeader flightroom={room} onClickInfo={() => setIsSideModalOpen(prevState => !prevState)} />
      {isLoading || isRefetching ? (
        <Spinner />
      ) : (
        <GiftedChat
          infiniteScroll
          listViewProps={{
            onScroll: ({ nativeEvent }) => handleScroll(nativeEvent),
            scrollEventThrottle: 400,
          }}
          loadEarlier={false}
          messages={[...socketMessages, ...mappedMessages]}
          messagesContainerStyle={{ alignSelf: 'center', flex: 1, height: 'unset', width: '100%' }}
          renderChatEmpty={() => <ChatEmpty isLoading={isFetchingMessages} />}
          renderChatFooter={() =>
            imagePreview ? <ImagePreview image={imagePreview} onClose={() => setImagePreview(null)} /> : null
          }
          renderInputToolbar={() => null}
          renderLoadEarlier={() => null}
          renderMessage={props => (
            <GiftedChatMessage
              firstUnreadMessage={firstUnreadMessage}
              key={props.currentMessage.messageId}
              message={props}
              onSend={onSend}
              setAttachmentPreview={setAttachmentPreview}
              userId={profile.userId}
            />
          )}
          user={{ _id: profile.userId }}
        />
      )}
      <InputToolbar flightroom={room} onSend={onSend} />
      <SideModal
        bottomButtons={
          !isScheduledForRoom ? (
            <Button isOutline onClick={() => setIsLeaveRoomModalOpen(prevState => !prevState)} theme="negative" type="button">
              {t('FLIGHTROOM.LEAVE.BUTTON')}
            </Button>
          ) : null
        }
        content={
          <>
            {crew?.map((crewMember: TCrewMember) => (
              <FlightroomInfoItem crewMember={crewMember} key={`crew-member-${crewMember.id}`} />
            ))}
          </>
        }
        isVisible={isSideModalOpen}
        onClose={() => setIsSideModalOpen(false)}
        title={t('FLIGHTROOM.DETAIL.TITLE')}
      />
      {/* Not using ModalOpener because this modal is opened out of another one (nested).
      Atm, this causes buggy behavior
      TODO: Fix ModalOpener to allow multiple open modals at once
      */}
      {isLeaveRoomModalOpen ? (
        <ConfirmationModal
          confirmText={t('FLIGHTROOM.LEAVE.ALERT.SUBMIT')}
          content={t('FLIGHTROOM.LEAVE.ALERT.DESCRIPTION')}
          isLoading={isLoadingLeaveFlightroom}
          onCancel={() => setIsLeaveRoomModalOpen(false)}
          onConfirm={() => leaveFlightroom(id)}
          title={t('FLIGHTROOM.LEAVE.ALERT.TITLE', { name })}
        />
      ) : null}
      {attachmentPreview ? (
        <ImagePreview image={attachmentPreview} isFullScreen onClose={() => setAttachmentPreview(null)} />
      ) : null}
    </div>
  );
};
