import { FC } from 'react';
import { useTranslation } from 'react-i18next';
import { Spinner, Text } from '@aviobook/web/_shared';
import { PaperAirplane } from 'assets';

import './chatEmpty.scss';

type TProps = {
  isLoading?: boolean;
};

export const ChatEmpty: FC<TProps> = ({ isLoading }) => {
  const { t } = useTranslation();
  return (
    <div className="empty-chat-container">
      {isLoading ? (
        <Spinner theme="normal" />
      ) : (
        <>
          <figure className="empty-chat-image">
            <img height={360} src={PaperAirplane} />
          </figure>
          <div className="empty-chat-text-container">
            <Text color="aviobookLightPrimaryAlpha" size="LG" uppercase weight="bold">
              {t('CHAT.EMPTY_NO_MESSAGES')}
            </Text>
            <Text color="aviobookLightPrimaryAlpha" size="SM" weight="regular">
              {t('CHAT.EMPTY_BREAK_ICE')}
            </Text>
          </div>
        </>
      )}
    </div>
  );
};
