import { FC } from 'react';
import { useTranslation } from 'react-i18next';
import { Navigate } from 'react-router-dom';
import { PageContent, Spinner } from '@aviobook/cms/_shared';
import { TTab } from '@aviobook/cms/_shared/pageContent/PageContent';
import { useRole } from '@aviobook/cms/connect/_screens/roles/role/_hooks/useRole';

import { RoleDetails } from './_tabs/roleDetails/RoleDetails';
import { RolePermissions } from './_tabs/rolePermissions/RolePermissions';
import { RoleTags } from './_tabs/roleTags/RoleTags';

export const Role: FC = () => {
  const { t } = useTranslation();
  const { data, isLoading, isError } = useRole();

  if (isLoading) {
    return <Spinner>{t('SHARED.LOADING_APPLICATION')}</Spinner>;
  }

  if (isError) {
    return <Navigate to="/admin/connect/roles" />;
  }

  const tabs: TTab[] = [
    {
      label: t('TAB.ROLES.ROLE.DETAILS'),
      name: 'Roles',
      permissions: ['VIEW_ROLES', 'UPDATE_ROLES'],
      screen: () => <RoleDetails data={data} isLoadingRole={isLoading} />,
    },
    {
      label: t('TAB.ROLES.ROLE.PERMISSIONS'),
      name: 'Permissions',
      permissions: ['UPDATE_ROLES', 'VIEW_ROLES'],
      screen: () => <RolePermissions />,
    },
    {
      label: t('TAB.ROLES.ROLE.QUICK_REPLIES'),
      name: 'tags',
      permissions: ['VIEW_ROLES', 'UPDATE_ROLES'],
      screen: () => <RoleTags />,
    },
  ];

  return <PageContent navBarClassName="top-nav-bar" tabs={tabs} textSize="XL" />;
};
