export enum languages {
  Dutch = 'nl-BE',
  English = 'en',
}

export function labelForLocale(locale: string): 'SHARED.LOCALE.EN' | 'SHARED.LOCALE.NL' {
  switch (locale) {
    case languages.English:
      return 'SHARED.LOCALE.EN';
    case languages.Dutch:
      return 'SHARED.LOCALE.NL';
    default:
      return null;
  }
}
