import { useTranslation } from 'react-i18next';
import { HttpClient, TApiError } from '@aviobook/_http';
import { queryClient } from '@aviobook/index';
import { toastError, toastSuccess } from '@aviobook/Toastify';
import { useMutation } from '@tanstack/react-query';
import { TChatbot } from 'types/src/chatbot';

function chatbots(values: TChatbot): Promise<TChatbot> {
  return HttpClient.post('/api/v1/admin/chatbot', values);
}

export function useCreateChatbot({ onSuccess }) {
  const { t } = useTranslation();
  return useMutation<TChatbot, TApiError, TChatbot>(['chatbots'], chatbots, {
    onError: () => {
      toastError(t('TOAST_ERROR'));
    },
    onSuccess: () => {
      queryClient.invalidateQueries(['chatbots']);
      toastSuccess(t('TOAST_SUCCESS'));
      onSuccess();
    },
  });
}
