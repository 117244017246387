import { DetailedHTMLProps, FC, LabelHTMLAttributes } from 'react';

import { Text } from '../text/Text';

import './statusPill.scss';

// TODO: Style and import shared types
type TProps = {
  label: string;
} & DetailedHTMLProps<LabelHTMLAttributes<HTMLLabelElement>, HTMLLabelElement>;

export const StatusPill: FC<TProps> = ({ label, ...props }) => {
  return (
    <span className="status-pill-container" {...props}>
      <Text size="XS" uppercase weight="bold">
        {label}
      </Text>
    </span>
  );
};
