import { useTranslation } from 'react-i18next';
import { HttpClient } from '@aviobook/_http';
import { queryClient } from '@aviobook/index';
import { toastError, toastSuccess } from '@aviobook/Toastify';
import { useMutation } from '@tanstack/react-query';

function deleteTag(id: string) {
  return HttpClient.delete(`/api/v1/admin/tag/${id}`);
}

export function useDeleteTags() {
  const { t } = useTranslation();
  const mutation = useMutation(['deleteChatbot'], deleteTag);

  const deleteSelectedTags = async (ids: string[]) => {
    const promises = ids.map(id => mutation.mutateAsync(id));
    try {
      return Promise.all(promises);
    } catch (e) {
      toastError(t('TOAST_ERROR'));
    } finally {
      queryClient.invalidateQueries(['chatbots']);
      toastSuccess(t('TOAST_SUCCESS'));
    }
  };

  return { ...mutation, deleteSelectedTags };
}
