import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import { useAuthenticationContext } from '@aviobook/_context';
import { Button, Text } from '@aviobook/web/_shared';
import logo from 'assets/images/aviobook_logo.png';

import { useAcceptAgreement } from './_queries';

import './licenseAgreement.scss';

export const LicenseAgreement = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const { setEnableAuthentication } = useAuthenticationContext();

  const { mutate: acceptAgreement } = useAcceptAgreement({
    onSuccess: async () => {
      setEnableAuthentication(true);
      navigate('/dashboard');
    },
  });

  const [checked, setChecked] = useState(false);

  const onClickCheckbox = () => {
    setChecked(!checked);
  };

  const onContinue = () => {
    acceptAgreement();
  };

  return (
    <main className="license-agreement-container">
      <section className="license-agreement-content-container">
        <div className="license-agreement-content-container-inner">
          <img alt="logo" src={logo} width={160} />
          <Text as="h1" className="title" size="XL" weight="bold">
            {t('LICENSE_AGREEMENT.WEB.TITLE').toUpperCase()}
          </Text>
          <Text as="p" className="subtitle" size="SM" weight="bold">
            {t('LICENSE_AGREEMENT.WEB.SUBTITLE')}
          </Text>
          <Text size="XS" wrap>
            {t('LICENSE_AGREEMENT.EULA_CONTENT').replace(/\\n/gi, '\n')}
          </Text>
        </div>
      </section>
      <footer className="license-agreement-checkbox-container">
        <div className="license-agreement-checkbox-inner">
          <div className="checkbox-container">
            <input checked={checked} id="license-agreement-checkbox" onChange={onClickCheckbox} type="checkbox" />
            <Text as="span" className="checkbox-label" color="aviobookLightPrimaryAlpha" size="SM">
              <label htmlFor="license-agreement-checkbox">{t('LICENSE_AGREEMENT.WEB.CHECKBOX_LABEL')}</label>
            </Text>
          </div>
          <Button disabled={!checked} onClick={onContinue}>
            {t('LICENSE_AGREEMENT.CONTINUE')}
          </Button>
        </div>
      </footer>
    </main>
  );
};
