import { ComponentProps, FC } from 'react';
import { Icon, Text } from '@aviobook/web/_shared';
import classNames from 'classnames';
import { SYSTEM_MESSAGE_LEVEL } from 'types';

import './systemMessage.scss';

type TProps = {
  level: SYSTEM_MESSAGE_LEVEL;
  message: string;
  time: string;
};

export const SystemMessage: FC<TProps> = ({ level, message, time }) => {
  return (
    <div className={classNames('system-message-container', level)}>
      <div className="system-message">
        <Icon className="system-message-icon" size={2.5} {...getIconProps(level)} />
        <Text as="span" className="system-message-text" size="SM" weight="semiBold">
          {message}
        </Text>
      </div>
      <Text as="span" className="system-message-time" size="SM" weight="semiBold">
        {time}
      </Text>
    </div>
  );
};

const getIconProps = (systemMessageLevel: SYSTEM_MESSAGE_LEVEL): ComponentProps<typeof Icon> => {
  switch (systemMessageLevel) {
    case SYSTEM_MESSAGE_LEVEL.ATTENTION:
      return { name: 'triangleCoreAttention' };
    case SYSTEM_MESSAGE_LEVEL.CAUTION:
      return { name: 'triangleCoreCaution' };
    case SYSTEM_MESSAGE_LEVEL.WARNING:
      return { name: 'triangleCoreWarning' };
    case SYSTEM_MESSAGE_LEVEL.UNCLEAR:
      return { colorName: 'aviobookWebNeutralsLightLight01White', name: 'circularInfoOutline' };
    case SYSTEM_MESSAGE_LEVEL.IMPORTED:
      return { colorName: 'aviobookWebNeutralsLightLight01White', name: 'circularInfoOutline' };
    case SYSTEM_MESSAGE_LEVEL.OK:
      return { colorName: 'aviobookWebNeutralsLightLight01White', name: 'circularInfoOutline' };
    case SYSTEM_MESSAGE_LEVEL.UNKNOWN:
      return { colorName: 'aviobookWebNeutralsLightLight01White', name: 'circularInfoOutline' };
    default:
      return { colorName: 'aviobookWebNeutralsLightLight01White', name: 'circularInfoOutline' };
  }
};
