import { FC, MouseEvent } from 'react';
import { TIconName } from 'assets';
import * as ICONS from 'assets/svg';
import classnames from 'classnames';
import { COLORS, TColorName } from 'styles';

import './icon.scss';

type TProps = {
  className?: string;
  clickable?: boolean;
  // TODO: remove string for errors in files with not typed svg's
  colorName?: TColorName | string;
  disabled?: boolean;
  id?: string;
  label?: string;
  name: TIconName | string;
  onClick?: (value: MouseEvent) => void;
  size?: number;
};

const Icon: FC<TProps> = ({
  size = 2.5,
  name,
  onClick,
  disabled,
  className = '',
  label,
  clickable,
  id,
  colorName,
  ...otherProps
}) => {
  const Svg = ICONS[name];
  if (!Svg) return null;

  const sizeObject = size ? { height: `${size}rem`, width: `${size}rem` } : {};
  const isIconOnly = !clickable && !onClick;

  const IconComponent = (
    <i
      {...otherProps}
      className={classnames('icon', isIconOnly && className, { 'custom-color': !!colorName })}
      style={sizeObject}
    >
      <Svg {...sizeObject} color={COLORS[colorName]} />
    </i>
  );

  if (isIconOnly) return IconComponent;

  return (
    <button
      aria-label={label}
      className={classnames('plain', className)}
      disabled={disabled}
      id={id}
      onClick={value => onClick?.(value)}
      style={sizeObject}
      type="button"
    >
      {IconComponent}
    </button>
  );
};

export default Icon;
