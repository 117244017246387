import { FC, ReactNode } from 'react';
import { TValidatorResponse } from '@aviobook/_utils/formValidation';
import classnames from 'classnames';

import ErrorMessage from '../errorMessage/ErrorMessage';
import Icon from '../icon/Icon';
import { Text } from '../text/Text';

import './inputWrapper.scss';

/**
 * This component serves as a wrapper around the specific input components. It contains some common input logic:
 *  - Show input label and icon
 *  - Show error message if needed
 *  - Show whether a field is required
 */

export type TInputWrapperProps = {
  className?: string;
  disabled?: boolean;
  label?: string;
  labelIcon?: string;
  name: string;
  required?: boolean;
  showInlineBottomError?: boolean;
  validation?: TValidatorResponse;
};

const InputWrapper: FC<TInputWrapperProps & { children: ReactNode; showError?: boolean }> = ({
  children,
  className = '',
  showInlineBottomError = false,
  disabled,
  label,
  labelIcon,
  name,
  required,
  showError,
  validation,
}) => (
  <div className={classnames('input-wrapper', { disabled, error: showError }, className)}>
    {!!label && (
      <label className="input-wrapper-label" htmlFor={name}>
        {!!labelIcon && <Icon name={labelIcon} />}
        <Text as="span" color="aviobookCMSLightBlue" size="XS" uppercase weight="bold">
          {label}
        </Text>
        {required ? <sup>*</sup> : null}
      </label>
    )}
    {children}
    {showInlineBottomError && <ErrorMessage isVisible={showError}>{validation?.message}</ErrorMessage>}
  </div>
);

export default InputWrapper;
