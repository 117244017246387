import { FC, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import { useNotificationsContext } from '@aviobook/_context';
import { Icon, Spinner, Text } from '@aviobook/web/_shared';
import classNames from 'classnames';

import { NotificationCenterItem } from './_components';
import { useGetNotifications } from './_queries';

import './notificationCenter.scss';

export const NotificationCenter: FC = () => {
  const {
    isLoading: isLoadingNotifications,
    isRefetching: isRefetchingNotifications,
    refetch: refetchNotifications,
  } = useGetNotifications({}, { enabled: false });

  useEffect(() => {
    refetchNotifications();
  }, []);

  const { notifications, setNotificationsRead } = useNotificationsContext();

  const { t } = useTranslation();
  const navigate = useNavigate();

  const showLoader = isLoadingNotifications || isRefetchingNotifications;

  return (
    <aside className="notification-center-container">
      <header className="notification-center-top-container">
        <section className="notification-center-top-header-left" />
        <section className="notification-center-top-header-middle">
          <Text color="aviobookWebPrimaryZulu08" size="MD" weight="bold">
            {t('NOTIFICATION_CENTER.TITLE')}
          </Text>
        </section>
        <section className="notification-center-top-header-right">
          <Icon colorName="aviobookWebPrimaryZulu08" name="closeCross" onClick={() => navigate(-1)} />
        </section>
      </header>
      <section
        className={classNames('notification-center-content-container', {
          'is-empty-notification-center': notifications.length === 0,
          'is-loading-notification-center': showLoader,
        })}
      >
        {showLoader ? (
          <Spinner size="large" />
        ) : notifications.length === 0 ? (
          <Text className="notifications-center-empty-notifications" color="aviobookDarkLegacyMainBravo" size="MD" weight="bold">
            {t('NOTIFICATION_CENTER.EMPTY_NOTIFICATIONS')}
          </Text>
        ) : (
          notifications?.map((notification, index) => (
            <NotificationCenterItem
              key={`notification-center-item-${index}`}
              notification={notification}
              setNotificationsRead={setNotificationsRead}
            />
          ))
        )}
      </section>
    </aside>
  );
};
