import { format, isAfter, isBefore, isValid, parse, formatDistanceToNow } from 'date-fns';

export const DEFAULT_DATE_STRING_FORMAT = 'dd/MM/yyyy';
export const DEFAULT_DATETIME_STRING_FORMAT = 'dd/MM/yyyy HH:mm';
export const DEFAULT_TIME_STRING_FORMAT = 'HH:mm';
export const DEFAULT_FLIGHT_DEPARTURE = 'E dd MMM';

export function formatDate(date: Date, formatString = DEFAULT_DATE_STRING_FORMAT): string | null {
  if (!isValid(date)) return null;
  return format(date, formatString);
}

export function formatTime(time: string | Date): string | null {
  if (!time) return null;
  return formatDate(new Date(time), DEFAULT_TIME_STRING_FORMAT);
}

export function formatISOString(isoString?: string, formatString = DEFAULT_DATE_STRING_FORMAT): string | null {
  if (!isoString) return null;
  return formatDate(new Date(isoString), formatString);
}

export function dateFromString(dateString: string, formatString = DEFAULT_DATE_STRING_FORMAT): Date | null {
  if (!dateString) return null;
  const date = parse(dateString, formatString, new Date());
  if (!isValid(date)) return null;
  return date;
}

export function dateFromTime(timeString: string): Date | null {
  return dateFromString(timeString, DEFAULT_TIME_STRING_FORMAT);
}

export function dateFromDateAndTime(date: string, time: string): Date | null {
  if (!date || !time) return null;
  return dateFromString(`${date} ${time}`, `${DEFAULT_DATE_STRING_FORMAT} ${DEFAULT_TIME_STRING_FORMAT}`);
}

export function ISOStringFromDate(date?: Date): string | null {
  if (!isValid(date) || !date) return null;
  return date.toISOString();
}

export function isAfterDate(date: Date, minDate: Date): boolean {
  return isAfter(date, minDate);
}

export function isBeforeDate(date: Date, maxDate: Date): boolean {
  return isBefore(date, maxDate);
}

export function formatRelativeTime(date: string | Date): string | null {
  if (!date) return null;
  const distance = formatDistanceToNow(new Date(date), { addSuffix: true });
  // We cut `about` from the string because there is no way to format this in date-fns.
  return distance.replace(/^about\s/, '');
}
