import { FC } from 'react';
import { useTranslation } from 'react-i18next';
import { TResponse } from '@aviobook/_hooks/useForm';
import { Checkbox, FlexComponent, InputField, PageContentBlock, SaveButton, Text } from '@aviobook/cms/_shared';
import { TRole } from 'types';

import './roleForm.scss';

type TProps = {
  form: TResponse<TRole, TRole>;
};

export const RoleForm: FC<TProps> = ({ form }) => {
  const { t } = useTranslation();

  return (
    <form onSubmit={form.submit}>
      <div className="role-form-top-container">
        <SaveButton isDisabled={!form.isDirty} />
      </div>
      <PageContentBlock title={t('ROLESFORM.TITLE.DETAILS')}>
        <FlexComponent flexDirection="row" gap={3} width="auto">
          <InputField
            disabled
            label={t('ROLESFORM.LABEL.NAME')}
            name="airlineRole"
            onChange={form.setAttribute}
            value={form.values?.airlineRole || ''}
          />
          <div className="role-form-checkbox-airline-role">
            <Checkbox
              checked={form.values?.isTaggable}
              name="airlineRole"
              onChange={value => form.setAttribute(value, 'isTaggable')}
            />
            <Text color="aviobookLightPrimaryAlpha" size="SM" weight="regular">
              {t('ROLESFORM.CHECKBOX.TEXT')}
            </Text>
          </div>
        </FlexComponent>
      </PageContentBlock>
    </form>
  );
};
