// TODO: add back 'Feedback' when Aviobook BE is ready to support feedback, import { Feedback } from '@aviobook/web/feedback/Feedback';

import { useTranslation } from 'react-i18next';
import { Navigate, Route, Routes } from 'react-router-dom';
import { useAppInitializer } from '@aviobook/web/_hooks/useAppInitializer';
import { AuthorizedLayout } from '@aviobook/web/_routing/authorized-layout/AuthorizedLayout';
import { Spinner } from '@aviobook/web/_shared';
import { About } from '@aviobook/web/about/About';
import { useAuthenticate } from '@aviobook/web/auth/_queries';
import Login from '@aviobook/web/auth/login/Login';
import Verify from '@aviobook/web/auth/verify/Verify';
import { Flightrooms } from '@aviobook/web/flightrooms/Flightrooms';
import { LicenseAgreement } from '@aviobook/web/license-agreement/LicenseAgreement';
import { NotificationCenter } from '@aviobook/web/notification-center/NotificationCenter';
import { Profile } from '@aviobook/web/profile/Profile';

import './index-web.scss';

export const AppWeb = () => {
  const { t } = useTranslation();
  const { data: profile, isLoading } = useAuthenticate();

  if (isLoading) {
    return <Spinner overlay>{t('SHARED.LOADING_APPLICATION')}</Spinner>;
  }
  return (
    <Routes>
      <Route element={profile ? <AuthenticatedWebRoutes /> : <NotAuthenticatedWebRoutes />} path="*" />
    </Routes>
  );
};

export const AuthenticatedWebRoutes = () => {
  useAppInitializer();
  return (
    <Routes>
      <Route element={<AuthorizedLayout />} path="dashboard/*">
        <Route element={<Flightrooms />} path="flightrooms" />
        <Route element={<Profile />} path="profile" />
        <Route element={<NotificationCenter />} path="notification-center" />
        <Route element={<About />} path="about" />
        <Route element={<Navigate to="flightrooms" />} path="*" />
      </Route>
      <Route element={<Navigate to="dashboard" />} path="*" />
    </Routes>
  );
};

export const NotAuthenticatedWebRoutes = () => (
  <Routes>
    <Route element={<Login />} path="auth/login" />
    <Route element={<Verify />} path="auth/verify/:token" />
    <Route element={<LicenseAgreement />} path="auth/license-agreement" />
    <Route element={<Navigate to="auth/login" />} path="*" />
  </Routes>
);
