import { FC, useEffect } from 'react';
import { Icon } from '@aviobook/web/_shared';
import classNames from 'classnames';

import './imagePreview.scss';

type TProps = {
  image: string;
  isFullScreen?: boolean;
  onClose?: () => void;
};

export const ImagePreview: FC<TProps> = ({ image, isFullScreen, onClose }) => {
  useEffect(() => {
    const handleKeyDown = (event: KeyboardEvent) => {
      if (event.key === 'Escape' && onClose) {
        onClose();
      }
    };

    document.addEventListener('keydown', handleKeyDown);

    return () => {
      document.removeEventListener('keydown', handleKeyDown);
    };
  }, [onClose]);

  return (
    <div
      className={classNames('chat-image-preview-container', {
        isFullScreen,
      })}
    >
      <Icon
        className="close-icon"
        colorName={isFullScreen && 'coreWhite100'}
        name="circularCrossFullOutline"
        onClick={onClose}
        size={2.5}
      />
      <img className="image-preview" crossOrigin="anonymous" src={image} />
    </div>
  );
};
