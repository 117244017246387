import { useEffect } from 'react';
import { useNotificationsContext } from '@aviobook/_context';
import { connectAllSockets, disconnectAllSockets, flightRoomSocket, personalInfoSocket } from '@aviobook/_utils/socket';
import { queryClient } from '@aviobook/index';
import { useGetNotifications } from '@aviobook/web/notification-center/_queries';
import { useUsersStatusContext } from 'shared';

import { useAuthenticate } from '../auth/_queries';

export const useAppInitializer = () => {
  const { data: profile, isLoading } = useAuthenticate({
    retry: false,
  });

  const { setNotificationsRead, onNotificationUpdate } = useNotificationsContext();
  useGetNotifications({}, { enabled: Boolean(profile) });
  const { updateStatus } = useUsersStatusContext();

  useEffect(() => {
    const updateFlightRoom = () => {
      queryClient.invalidateQueries(['getFlightrooms, getAddableFlightrooms']);
    };

    if (profile) {
      connectAllSockets();
      personalInfoSocket.on(`notifications-update`, onNotificationUpdate);
      personalInfoSocket.on('notifications-read-update', setNotificationsRead);

      flightRoomSocket.on('online-indicator-change', updateStatus);
      flightRoomSocket.on('flight-room-update', updateFlightRoom);
      flightRoomSocket.on('flight-room-create', updateFlightRoom);
      flightRoomSocket.on('flight-room-delete', updateFlightRoom);

      personalInfoSocket.on('flight-room-delete', updateFlightRoom);
      personalInfoSocket.on('personal-info-update', updateFlightRoom);

      personalInfoSocket.on('update-unread', updateFlightRoom);

      return () => {
        flightRoomSocket.on('online-indicator-change', updateStatus);
        disconnectAllSockets();
      };
    }
  }, [profile]);

  return { isAppLoaded: isLoading, isLoggedIn: Boolean(profile) };
};
