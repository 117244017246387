import { FC } from 'react';

import { Text } from '../text/Text';

import './emptyState.scss';

type TProp = {
  button?: () => JSX.Element;
  description: string;
  title: string;
};
export const EmptyState: FC<TProp> = ({ button: Button, description, title }) => {
  return (
    <main className="content-container">
      <div className="content">
        <Text as="span" color="aviobookLightPrimaryAlpha" size="LG" uppercase weight="bold">
          {title}
        </Text>
        <Text as="span" className="description" color="aviobookCMSLightBlue" size="MD" weight="regular">
          {description}
        </Text>
        <Button />
      </div>
    </main>
  );
};
