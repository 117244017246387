import { FC, ReactNode } from 'react';
import classnames from 'classnames';

import { Icon } from '../index';
import { ModalOpener } from './ModalOpener';

import './modal.scss';

type TProps = {
  children: ReactNode;
  className?: string;
  onCancel?: () => void;
};

type THeaderProps = {
  children: ReactNode;
  onCancel?: () => void;
};

const Header = ({ children, onCancel }: THeaderProps) => {
  const cancelModal = () => {
    onCancel?.();
    ModalOpener.instance.close();
  };
  return (
    <header className="modal-header">
      <h1>{children}</h1>
      <Icon colorName="aviobookDarkSecondaryHotel" name="closeCross" onClick={cancelModal} size={2.5} />
    </header>
  );
};

const Content: FC<{ children: ReactNode }> = ({ children }) => <div className="modal-content">{children}</div>;

const Buttons: FC<{ children: ReactNode }> = ({ children }) => <div className="modal-buttons">{children}</div>;

const Modal: FC<TProps> = ({ children, className = '', onCancel }) => {
  const cancelModal = () => {
    onCancel?.();
    ModalOpener.instance.close();
  };

  return (
    <>
      <button className="modal-dimmer" onClick={cancelModal} type="button" />
      <div className={classnames('modal', className)}>
        <div className="modal-box">{children}</div>
        <div className="modal-close-icon">
          <Icon name="SvgClose" onClick={cancelModal} size={3} />
        </div>
      </div>
    </>
  );
};

export default Object.assign(Modal, { Buttons, Content, Header });
