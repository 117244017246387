import { FC, useEffect } from 'react';
import { Icon, Text } from '@aviobook/web/_shared';
import clsx from 'clsx';
import { isAfter } from 'date-fns';
import { DEFAULT_FLIGHT_DEPARTURE, formatISOString, formatTime, getFlightroomAirportNames, useUsersStatusContext } from 'shared';
import { TFlightroom } from 'types';

import './flightroomItem.scss';

type TProps = {
  alternativeColor?: boolean;
  flightroom: TFlightroom;
  id: string;
  isSelected?: boolean;
  onClick: (flightroom: TFlightroom) => void;
};

export const FlightroomItem: FC<TProps> = ({ alternativeColor, isSelected, flightroom, onClick }) => {
  const { setCrewMembersStatus } = useUsersStatusContext();

  const estimatedDeparture = flightroom.estimatedTimeDeparture || flightroom.scheduledTimeDeparture;
  const estimatedArrival = flightroom.estimatedTimeArrival || flightroom.scheduledTimeArrival;

  const isDepartureDelayed = isAfter(new Date(estimatedDeparture), new Date(flightroom.scheduledTimeDeparture));
  const isArrivalDelayed = isAfter(new Date(estimatedArrival), new Date(flightroom.scheduledTimeArrival));

  const flightroomItemClasses = clsx('flightroom-item-container', {
    'alternative-color': alternativeColor,
    isDisabled: flightroom.isLocked,
    selected: isSelected,
  });

  const { arrivalAirport, departureAirport } = getFlightroomAirportNames(flightroom);

  useEffect(() => {
    if (flightroom.crew) setCrewMembersStatus(flightroom.crew);
  }, [flightroom.crew]);

  return (
    <article className={flightroomItemClasses} onClick={() => onClick(flightroom)}>
      <header className="flightroom-item-top-container">
        <div className="flightroom-item-top-left" />
        <div className={clsx('flightroom-item-top-middle')}>
          <Text color="aviobookLightPrimaryAlpha" size="SM" weight="semiBold">
            {flightroom.name}
          </Text>
        </div>
        <div className="flightroom-item-top-right">
          {!isSelected && flightroom.amtMessagesUnread > 0 && (
            <div className="flightroom-item-unread-dot">
              <Text color="aviobookDarkPrimaryAlpha" size="SM" weight="bold">
                {flightroom.amtMessagesUnread}
              </Text>
            </div>
          )}
          {flightroom.isLocked && <Icon name="securityLockOutline" />}
        </div>
      </header>
      <section className="flightroom-item-center-container">
        <div className="flightroom-item-time-slot">
          <Text color="darkPrimaryBravo" size="XL">
            {departureAirport}
          </Text>
          <Text color="aviobookWebAccentRomeo07" size="SM" weight="semiBold">
            {formatTime(flightroom.scheduledTimeDeparture)}
          </Text>
          {flightroom.estimatedTimeDeparture && (
            <Text color={isDepartureDelayed ? 'coreCaution' : 'coreSuccess'} size="SM" weight="semiBold">
              {formatTime(flightroom.estimatedTimeDeparture)}
            </Text>
          )}
        </div>
        <div className="flightroom-item-center">
          <div className="flightroom-item-center-date">
            <div className="flightroom-item-line flightroom-item-line-left" />
            <Icon colorName="aviobookWebAccentRomeo07" name="aircraftPlaneRightFilled" size={3.0} />
            <div className="flightroom-item-line flightroom-item-line-right" />
          </div>
          <Text color="darkPrimaryBravo" size="SM" weight="semiBold">
            {formatISOString(flightroom.scheduledTimeDeparture, DEFAULT_FLIGHT_DEPARTURE)}
          </Text>
        </div>
        <div className="flightroom-item-time-slot">
          <Text color="darkPrimaryBravo" size="XL">
            {arrivalAirport}
          </Text>
          <Text color="aviobookWebAccentRomeo07" size="SM" weight="semiBold">
            {formatTime(flightroom.scheduledTimeArrival)}
          </Text>
          {flightroom.estimatedTimeArrival && (
            <Text color={isArrivalDelayed ? 'coreCaution' : 'coreSuccess'} size="SM" weight="semiBold">
              {formatTime(flightroom.estimatedTimeArrival)}
            </Text>
          )}
        </div>
      </section>
      {/* <div className="flightroom-item-bottom-container">
        <Text weight="semiBold" size="XS" color="aviobookLightPrimaryAlpha" className="lightroom-item-bottom-left-text">
          test:
          <Text size="XS" as="span" className="lightroom-item-bottom-left-text">
            The alternate airport has been closed. You will sijjksfhsdjkhfjksdhfjksdhfjsdhfjksdfhjksdhfjksdhfjksdhfk
          </Text>
        </Text>
        <Text size="XS" color="aviobookLightPrimaryAlpha" uppercase>
          10:20
        </Text>
      </div> */}
    </article>
  );
};
