import { useTranslation } from 'react-i18next';
import { useNavigate, useSearchParams } from 'react-router-dom';
import { useForm } from '@aviobook/_hooks/';
import { TFormValidationErrors } from '@aviobook/_hooks/useForm';
import { formValidator } from '@aviobook/_utils/formValidation';
import { Spinner } from '@aviobook/cms/_shared';
import { useAuthenticateCMS } from '@aviobook/cms/auth/_queries';
import { TChatbot } from 'types/src/chatbot';

import { useChatbot } from '../_queries/useGetChatbot';
import { useUpdateChatbot } from '../_queries/useUpdateChatbot';
import { ChatbotForm } from './ChatbotForm';

function validateForm(values: TChatbot): TFormValidationErrors<TChatbot> {
  return {
    apiKey: formValidator.required(values.apiKey),
    name: formValidator.required(values.name),
    url: formValidator.required(values.url),
  };
}

export const UpdateChatbot = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const [searchParams] = useSearchParams();
  const id = searchParams.get('id') || '';
  const { data, isLoading: isLoadingChatbot } = useChatbot(id);
  const { hasPermission } = useAuthenticateCMS();
  const { isLoading, mutate } = useUpdateChatbot({
    onSuccess: () => {
      return navigate('/admin/connect');
    },
  });
  const form = useForm<TChatbot>({
    initialForm: data,
    submitForm: values => mutate(values),
    validateForm: values => validateForm(values),
  });

  if (isLoading || isLoadingChatbot || !data) {
    return <Spinner>{t('SHARED.LOADING_APPLICATION')}</Spinner>;
  }

  return (
    <main className="chatbot-container">
      <ChatbotForm canSave={hasPermission('UPDATE_CHATBOTS')} form={form} />
    </main>
  );
};
