import { HttpClient, TApiError } from '@aviobook/_http';
import { MutationOptions, useMutation } from '@tanstack/react-query';
import { TProfile } from 'types';

import { TLoginForm } from '../_models';

function login(body: TLoginForm): Promise<TProfile> {
  return HttpClient.post<TProfile>('/api/v1/auth/login', { ...body, email: body.email.toLowerCase() });
}

export function useLogin(options?: MutationOptions<TProfile, TApiError, TLoginForm>) {
  // const { setProfile } = useAuthenticationContext();

  return useMutation<TProfile, TApiError, TLoginForm>(['login'], login, {
    ...options,
    // onSuccess: (data, variables, context) => {
    //   setProfile(data);
    //   if (options?.onSuccess) options.onSuccess(data, variables, context);
    // },
  });
}
