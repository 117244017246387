import { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { useAuthenticationContext } from '@aviobook/_context';
import { HttpClient, TApiError } from '@aviobook/_http';
import { useMutation } from '@tanstack/react-query';
import { TProfile } from 'types';

function verify(resetToken: string): Promise<TProfile> {
  return HttpClient.post<TProfile>('/api/v1/auth/verify', { resetToken });
}

export function useVerify() {
  const navigate = useNavigate();
  const { setEnableAuthentication } = useAuthenticationContext();

  useEffect(() => {
    setEnableAuthentication(false);
  }, []);

  return useMutation<TProfile, TApiError, string>(['verify'], verify, {
    onError: () => {
      navigate('/auth');
    },
    onSuccess: async profile => {
      if (profile?.hasAcceptedLicenseAgreement) {
        setEnableAuthentication(true);
        navigate('/dashboard');
      } else {
        navigate('/auth/license-agreement');
      }
    },
  });
}
