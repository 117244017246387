import { useTranslation } from 'react-i18next';
import { HttpClient } from '@aviobook/_http';
import { queryClient } from '@aviobook/index';
import { toastError, toastSuccess } from '@aviobook/Toastify';
import { useMutation } from '@tanstack/react-query';
import { TTag } from 'types/src/tag';

function updateTag(values: TTag): Promise<TTag[]> {
  return HttpClient.post('/api/v1/admin/tag', { description: values.description, text: values.text });
}

export function useUpdateTags() {
  const { t } = useTranslation();
  return useMutation(['tags'], updateTag, {
    onError: () => {
      toastError(t('TOAST_ERROR'));
    },
    onSuccess: () => {
      queryClient.invalidateQueries(['tags']);
      toastSuccess(t('TOAST_SUCCESS'));
    },
  });
}
