import { FC } from 'react';
import { useTranslation } from 'react-i18next';

import { Button } from '../index';
import { Text } from '../text/Text';

type TProps = {
  deleteButtonDisabled?: boolean;
  onClick: () => void;
};

export const DeleteButton: FC<TProps> = ({ deleteButtonDisabled = false, onClick }) => {
  const { t } = useTranslation();
  return (
    <Button disabled={deleteButtonDisabled} isOutline onClick={onClick} theme="delete">
      <Text as="span" color="iosSystemAccentsSystemRedLight" weight="bold">
        {t('SHARED.BUTTONS.DELETE')}
      </Text>
    </Button>
  );
};
