import { FC } from 'react';
import { Icon, Text } from '@aviobook/web/_shared';
import clsx from 'clsx';
import { DEFAULT_FLIGHT_DEPARTURE, formatISOString, getFlightroomAirportNames } from 'shared';
import { TFlightroom } from 'types';

import './flightroomSearchItem.scss';

type TProps = {
  alternativeColor?: boolean;
  canJoinFlightRooms: boolean;
  clickCallback: (input: TFlightroom) => void;
  flightroom: TFlightroom;
  isSelected?: boolean;
};

export const FlightroomSearchItem: FC<TProps> = ({
  alternativeColor,
  isSelected,
  flightroom,
  canJoinFlightRooms,
  clickCallback,
}) => {
  const { arrivalAirport, departureAirport } = getFlightroomAirportNames(flightroom);

  const flightroomSearchItemClasses = clsx('flightroom-search-item-container', {
    'alternative-color': alternativeColor,
    disabled: !canJoinFlightRooms,
    selected: isSelected,
  });

  const getIconName = () => {
    if (flightroom.isLocked) {
      return 'securityLockFilled';
    }
    if (flightroom.isMemberOfRoom) {
      return 'circularAccepted';
    }

    return 'circularPlusFilledRegular';
  };

  const handleClick = (event: React.UIEvent<HTMLButtonElement>) => {
    event.preventDefault();

    if (canJoinFlightRooms) {
      clickCallback(flightroom);
    }
  };

  return (
    <button className={flightroomSearchItemClasses} onClick={handleClick}>
      <section className="flightroom-search-search-item-left">
        <Text color="aviobookDarkSecondaryHotel" size="SM" weight="semiBold">
          {flightroom.name}
        </Text>
      </section>
      <section className="flightroom-search-search-item-center">
        <div className="flightroom-search-search-item-center-airports">
          <Text color="darkPrimaryBravo" size="XL" uppercase>
            {departureAirport}
          </Text>
          <Icon colorName="aviobookWebAccentRomeo07" name="aircraftPlaneRightFilled" size={2} />
          <Text color="darkPrimaryBravo" size="XL" uppercase>
            {arrivalAirport}
          </Text>
        </div>
        <Text color="aviobookWebPrimaryZulu05" size="SM" weight="semiBold">
          {formatISOString(flightroom.scheduledTimeDeparture, DEFAULT_FLIGHT_DEPARTURE)}
        </Text>
      </section>
      {canJoinFlightRooms ? (
        <section className="flightroom-search-search-item-right">
          <Icon name={getIconName()} size={2.5} />
        </section>
      ) : null}
    </button>
  );
};
