import { HttpClient, THttpMetadataQuery, THttpSortDirection, TRoomArrivalStatus } from '@aviobook/_http';
import { useInfiniteQuery } from '@tanstack/react-query';
import { TFlightroomData } from 'types';

import { useDebounce } from '../../../_hooks';

type TFlightroomPayload = {
  search?: string;
};

const FLIGHTROOMS_PER_PAGE = 20;

const query: THttpMetadataQuery = {
  roomArrivalStatus: TRoomArrivalStatus.All,
  skip: 0,
  sortBy: 'lastInteractionDate',
  sortDirection: THttpSortDirection.Ascending,
  take: FLIGHTROOMS_PER_PAGE,
};

function getAddableFlightrooms(payload?: TFlightroomPayload): Promise<TFlightroomData> {
  return HttpClient.get<TFlightroomData>(`/api/v1/rooms/add`, { ...payload, roomArrivalStatus: TRoomArrivalStatus.All });
}

export function useGetAddableFlightrooms(payload?: TFlightroomPayload) {
  const search = useDebounce(payload.search, 500);

  return useInfiniteQuery(
    ['getAddableFlightrooms', query, search],
    context => {
      return getAddableFlightrooms({ ...query, search, ...context.pageParam });
    },
    {
      getNextPageParam: (lastPage, pages) => {
        if (!lastPage || !lastPage.meta || pages.length * FLIGHTROOMS_PER_PAGE > lastPage.meta.totalCount) {
          return undefined;
        }
        return { ...query, skip: lastPage.meta.skip + lastPage.meta.count };
      },
    },
  );
}
