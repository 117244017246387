import React, { ChangeEvent, useEffect, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { InputToolbarProps } from 'react-native-gifted-chat';
import { useAppContext } from '@aviobook/_context/AppContext';
import { toBase64 } from '@aviobook/_utils/fileHelpers';
import { Button, Icon, InputField } from '@aviobook/web/_shared';
import Modal from '@aviobook/web/modal/Modal';
import { ModalOpener } from '@aviobook/web/modal/ModalOpener';
import prettyBytes from 'pretty-bytes';
import { TGiftedMessage } from 'shared';
import { TFlightroom } from 'types';

import { useGetTags } from '../../_queries';
// import { Suggestions } from '../suggestions/Suggestions';
import { Tags } from '../tags/Tags';

import './inputToolbar.scss';

const MAX_IMAGE_SIZE = 1.2e7; // 12mb

type TProps = InputToolbarProps<TGiftedMessage> & {
  flightroom: TFlightroom;
  onSend: (message: string, attachment: string) => void;
};

export const InputToolbar: React.FC<TProps> = ({ flightroom, onSend }) => {
  const inputRef = useRef<HTMLInputElement | null>(null);
  const inputFile = useRef<HTMLInputElement | null>(null);

  const { t } = useTranslation();
  const { data: tags = [] } = useGetTags(flightroom.id);

  const [isMentioning, setIsMentioning] = useState(false);
  const [message, setMessage] = useState('');
  const { setImagePreview, imagePreview } = useAppContext();

  useEffect(() => {
    const listener = (event: KeyboardEvent) => {
      if (event.code === 'Enter' || event.code === 'NumpadEnter') {
        event.preventDefault();
        sendMessage();
      }
    };
    document.addEventListener('keydown', listener);
    return () => {
      document.removeEventListener('keydown', listener);
    };
  }, [flightroom.id, imagePreview]);

  const onPressTag = (item: string) => setMessage(msg => (message ? `${msg} ${item}` : item));

  const sendMessage = () => {
    setMessage(msg => {
      onSend(msg, imagePreview);
      return '';
    });
    setImagePreview('');
  };

  const handleInputChange = (value: string) => {
    const words = value.split(' ');
    const lastWord = words[words.length - 1];

    // Check if the last word starts with '@'
    setIsMentioning(lastWord.startsWith('@'));
  };

  const handlePickAttachment = async (event: ChangeEvent<HTMLInputElement>) => {
    const file = event.target.files[0];
    if (file.size > MAX_IMAGE_SIZE) {
      ModalOpener.instance.open({
        render: () => (
          <Modal>
            <Modal.Header>{t('CHAT.INPUT.ALERT.ATTACHMENT_TOO_LARGE.TITLE')}</Modal.Header>
            <Modal.Content>
              {t('CHAT.INPUT.ALERT.ATTACHMENT_TOO_LARGE.DESCRIPTION', { maxSize: prettyBytes(MAX_IMAGE_SIZE) })}
            </Modal.Content>
            <Modal.Buttons>
              <Button onClick={() => ModalOpener.instance.close()} theme="primary">
                {t('SHARED.BUTTONS.OK')}
              </Button>
            </Modal.Buttons>
          </Modal>
        ),
      });
    } else {
      const base64Preview = await toBase64(file);
      setImagePreview(base64Preview as string);
    }
    event.target.value = '';
  };

  useEffect(() => {
    const handleKeyDown = (event: KeyboardEvent) => {
      if (event.key === 'Enter' || event.key === 'NumpadEnter') {
        if (isMentioning) {
          event.preventDefault();
        } else {
          sendMessage();
        }
      }
    };

    document.addEventListener('keydown', handleKeyDown);

    return () => {
      document.removeEventListener('keydown', handleKeyDown);
    };
  }, [isMentioning, () => sendMessage()]);

  const sendDisabled = !message && !imagePreview;

  return (
    <div className="chat-input-toolbar-container">
      <Tags onPressTag={onPressTag} tags={tags} />
      <div className="input-container">
        {/* TODO: Re enable this when tagging ready  */}
        {/* {isMentioning ? (
          <Suggestions
            appendMessage={message => setMessage(prevMessage => prevMessage + message)}
            inputRef={inputRef}
            setIsMentioning={setIsMentioning}
            taggableRoles={flightroom.taggableRoles}
          />
        ) : null} */}
        <InputField
          autoFocus
          className="input-field"
          iconName="circularPlusOutline"
          name="chat-input"
          onChange={x => {
            setMessage(x.toString());
            handleInputChange(x.toString());
          }}
          onIconClick={() => inputFile.current?.click()}
          placeholder={t('CHAT.INPUT.PLACEHOLDER')}
          ref={inputRef}
          value={message}
        />
        <Button className="send-button" disabled={sendDisabled} onClick={sendMessage}>
          <Icon colorName="aviobookDarkNeutralsLight" name="paperplaneOutline" size={3} />
        </Button>
      </div>
      <input accept="image/*" id="file" onChange={handlePickAttachment} ref={inputFile} style={{ display: 'none' }} type="file" />
    </div>
  );
};
