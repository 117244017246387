import { HttpClient, TApiError } from '@aviobook/_http';
import { useQuery } from '@tanstack/react-query';
import { TRole } from 'types';

function getRoles(): Promise<TRole[]> {
  return HttpClient.get<TRole[]>('/api/v1/admin/role');
}

export function useGetRoles() {
  return useQuery<TRole[], TApiError>(['roles'], getRoles);
}
