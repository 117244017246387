import { FC } from 'react';
import { useTranslation } from 'react-i18next';
import { useGetAddableFlightrooms } from '@aviobook/web/flightrooms/_queries/useGetAddableFlightrooms';
import classNames from 'classnames';
import { useFlightroomsContext } from 'shared';
import { TFlightroom } from 'types';

import { Spinner, Text } from '../../_shared';
import { ModalOpener } from '../../modal/ModalOpener';
import { RequestFlightroomAccessModal } from '../../modal/requestFlightroomAccessModal/RequestFlightroomAccessModal';
import { FlightroomSearchItem } from './flightroomSearchItem/FlightroomSearchItem';

type TProps = {
  searchQuery: string;
};
export const SearchedFlightRoomList: FC<TProps> = ({ searchQuery }) => {
  const { t } = useTranslation();
  const { openRoom, selectedFlightroomId } = useFlightroomsContext();
  const {
    data,
    isLoading: isLoadingSearch,
    fetchNextPage,
    isFetchingNextPage,
    hasNextPage,
  } = useGetAddableFlightrooms({ search: searchQuery });
  const canJoinFlightRooms = data?.pages[0]?.meta.canJoinFlightRooms;

  const onClickSearchableFlightroom = async (room: TFlightroom) => {
    if (room.isLocked) {
      return;
    }

    if (room.isMemberOfRoom) {
      openRoom(room);
      return;
    }

    ModalOpener.instance.open({
      render: () => (
        <RequestFlightroomAccessModal
          canJoinFlightRooms={canJoinFlightRooms}
          content={t('FLIGHTROOMS.REQUEST_ACCESS.MESSAGE', { flightroom: room.name })}
          roomId={room.id}
          searchQuery={searchQuery}
          title={t('FLIGHTROOMS.REQUEST_ACCESS.TITLE')}
        />
      ),
    });
  };

  if (isLoadingSearch) {
    return (
      <section className={classNames('flightrooms-content-container', 'is-loading')}>
        <Spinner size="large" />
      </section>
    );
  }

  if (Array.isArray(data.pages) && data.pages.length === 0) {
    return (
      <section className={classNames('flightrooms-content-container', 'is-empty-search')}>
        <Text className="flightrooms-empty-search-text" color="aviobookDarkLegacyMainBravo" size="MD" weight="bold">
          {t('FLIGHTROOMS.EMPTY_SEARCH.TITLE')}, {t('FLIGHTROOMS.EMPTY_SEARCH.DESCRIPTION')}
        </Text>
      </section>
    );
  }

  const handleScroll = (e: React.UIEvent<HTMLDivElement>) => {
    const target = e.currentTarget;
    if (target.scrollHeight - target.scrollTop === target.clientHeight && !isFetchingNextPage && hasNextPage) {
      fetchNextPage();
    }
  };

  return (
    <section className={classNames('flightrooms-content-container')} onScroll={handleScroll}>
      {data?.pages.map(page =>
        page.data.map((room, index) => (
          <FlightroomSearchItem
            alternativeColor={index % 2 === 1}
            canJoinFlightRooms={canJoinFlightRooms}
            clickCallback={onClickSearchableFlightroom}
            flightroom={room}
            isSelected={room.id === selectedFlightroomId}
            key={`searchable-${room.id}`}
          />
        )),
      )}
      {isFetchingNextPage ? (
        <span className="flightrooms-content-fetching-spinner">
          <Spinner size="normal" />
        </span>
      ) : null}
    </section>
  );
};
