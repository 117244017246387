import React from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import { useForm } from '@aviobook/_hooks';
import { TFormValidationErrors } from '@aviobook/_hooks/useForm';
import { formValidator } from '@aviobook/_utils/formValidation';
import { Spinner } from '@aviobook/cms/_shared';
import { useAuthenticateCMS } from '@aviobook/cms/auth/_queries';
import { useCreateChatbot } from '@aviobook/cms/connect/_screens/general/_tabs/chatbots/_queries/useCreateChatbot';
import { ChatbotForm } from '@aviobook/cms/connect/_screens/general/_tabs/chatbots/chatbot/ChatbotForm';
import { TChatbot } from 'types/src/chatbot';

import './chatBot.scss';

function validateForm(values: TChatbot): TFormValidationErrors<TChatbot> {
  return {
    apiKey: formValidator.required(values.apiKey),
    name: formValidator.required(values.name),
    url: formValidator.required(values.url),
  };
}

const initialForm: TChatbot = {
  apiKey: '',
  chatbotKeywords: [],
  description: '',
  id: '',
  keywords: [],
  name: '',
  url: '',
};

export const CreateChatBot = () => {
  const navigate = useNavigate();
  const { t } = useTranslation();
  const { hasPermission } = useAuthenticateCMS();
  const { isLoading, mutate } = useCreateChatbot({
    onSuccess: () => {
      navigate('/admin/connect');
    },
  });
  const form = useForm<TChatbot>({
    initialForm,
    submitForm: values => mutate(values),
    validateForm: values => validateForm(values),
  });

  if (isLoading) {
    return <Spinner overlay>{t('SHARED.LOADING_APPLICATION')}</Spinner>;
  }

  return (
    <main className="chatbot-container">
      <ChatbotForm canSave={hasPermission('ADD_CHATBOTS')} form={form} />
    </main>
  );
};
