import { HttpClient, TApiError } from '@aviobook/_http';
import { MutateOptions, useMutation } from '@tanstack/react-query';

type TPayload = {
  hasBeenGrantedAccess: boolean;
  roomId: string;
  userIds: string[];
};

type TResponse = unknown;

function grantAccess(payload: TPayload): Promise<TResponse> {
  return HttpClient.post('/api/v1/rooms/grant-access', payload);
}

export function usePostGrantAccess(options?: MutateOptions<TResponse, TApiError, TPayload>) {
  return useMutation<TResponse, TApiError, TPayload>(['grantAccess'], grantAccess, options);
}
