import { HttpClient, TApiError } from '@aviobook//_http';
import { useQuery } from '@tanstack/react-query';
import { TTag } from 'types/src/tag';

function tags(): Promise<TTag[]> {
  return HttpClient.get('/api/v1/admin/tag');
}

export function useTags() {
  return useQuery<TTag[], TApiError>(['tags'], tags);
}
