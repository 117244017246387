import { Suspense } from 'react';
import { createRoot } from 'react-dom/client';
import { BrowserRouter } from 'react-router-dom';
import { compose } from '@aviobook/_utils/compose';
import { QueryClient, QueryClientProvider } from '@tanstack/react-query';
import { ReactQueryDevtools } from '@tanstack/react-query-devtools';
import { FlightroomsContextProvider, UsersStatusContextProvider } from 'shared';

import {
  AppContextProvider,
  AuthenticationContextProvider,
  NotificationsContextProvider,
  OfflineMessagesContextProvider,
} from './_context';
import { App } from './App';
import { Config } from './config';
import * as serviceWorker from './serviceWorker';
import { Spinner } from './web/_shared';

const Providers = compose([
  AppContextProvider,
  UsersStatusContextProvider,
  AuthenticationContextProvider,
  FlightroomsContextProvider,
  OfflineMessagesContextProvider,
  NotificationsContextProvider,
]);

export const queryClient = new QueryClient({
  defaultOptions: {
    ...Config.reactQuery,
    queries: {
      ...Config.reactQuery?.queries,
      refetchOnWindowFocus: false,
    },
  },
});

createRoot(document.getElementById('root')).render(
  <Suspense fallback={<Spinner overlay />}>
    <BrowserRouter>
      <QueryClientProvider client={queryClient}>
        <Providers>
          <App />
          <ReactQueryDevtools initialIsOpen={false} />
        </Providers>
      </QueryClientProvider>
    </BrowserRouter>
  </Suspense>,
);

serviceWorker.unregister();
