import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import licensesAsObject from '@aviobook/licenses.json';
import { Icon, Text } from '@aviobook/web/_shared';

import { TLicense } from './_models/license';
import { LicenseItem } from './licenseItem/LicenseItem';

import './about.scss';

export const About = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const licenses: TLicense[] = Object.entries(licensesAsObject).map(([name, fields]) => ({ name, ...fields }));
  const renderLicense = (license: TLicense) => <LicenseItem key={license.name} {...license} />;

  return (
    <aside className="about-container">
      <header className="about-header">
        <Icon colorName="colorsAviobookDarkPrimaryBravo450Clouds" name="arrowLeft" onClick={() => navigate(-1)} size={2.5} />
        <Text color="aviobookDarkSecondaryHotel" size="MD" weight="bold">
          {t('PROFILE.ABOUT')}
        </Text>
        <div className="header-equalizer-div" />
      </header>
      <div className="about-version-container">
        <Text color="aviobookDarkSecondaryHotel" size="SM" weight="semiBold">
          {t('ABOUT.VERSION')}
        </Text>
        <Text color="aviobookDarkSecondaryHotel" size="SM">
          {/* BUMP VERSION IN .ENV */}
          {process.env.REACT_APP_VERSION_NR}
        </Text>
      </div>
      <div className="about-licenses-container">
        <Text className="about-licenses-text" color="aviobookDarkSecondaryDelta" size="SM" weight="bold">
          {t('ABOUT.LICENSES')}
        </Text>
        <ul className="about-licenses-list">{licenses.map(renderLicense)}</ul>
      </div>
    </aside>
  );
};
