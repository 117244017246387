import { FC } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import { useNotificationsContext } from '@aviobook/_context';
import { TRoomArrivalStatus } from '@aviobook/_http';
import { TTabBar } from '@aviobook/web/flightrooms/_components/tabBar/TabBar.types';

import { Icon, SearchField, StatusPill } from '../../../_shared';
import { TabBar } from '../tabBar/TabBar';

type TProps = {
  isOnline: boolean;
  searchQuery: string;
  selectedFlightroomTab: TRoomArrivalStatus.Ongoing | TRoomArrivalStatus.Completed;
  setSearchQuery: (query: string) => void;
  setSelectedFlightroomTab: (roomStatus: TRoomArrivalStatus.Ongoing | TRoomArrivalStatus.Completed) => void;
};

export const FlightRoomsHeaderComponent: FC<TProps> = ({
  isOnline,
  searchQuery,
  setSearchQuery,
  setSelectedFlightroomTab,
  selectedFlightroomTab,
}) => {
  const navigate = useNavigate();
  const { t } = useTranslation();
  const { amtUnreadNotifications } = useNotificationsContext();

  // ADD TRANSLATIONS
  const tabs: TTabBar[] = [
    { onClick: () => setSelectedFlightroomTab(TRoomArrivalStatus.Ongoing), text: t('FLIGHTROOMS.SECTION.ACTIVE') },
    { onClick: () => setSelectedFlightroomTab(TRoomArrivalStatus.Completed), text: t('FLIGHTROOMS.SECTION.COMPLETED') },
  ];

  return (
    <header className="flightrooms-header-container">
      <div className="flightrooms-header-top-container">
        <div className="flightrooms-header-top-left" />
        {/* TODO CHECK if isOnline is needed */}
        <div className="flightrooms-header-top-center">{!isOnline && <StatusPill label="offline" />}</div>
        <div className="flightrooms-header-top-right">
          <button className="flightrooms-header-notification-icon" onClick={() => navigate('/dashboard/notification-center')}>
            {amtUnreadNotifications > 0 && <div className="flightrooms-header-notification-indicator" />}
            <Icon name="notificationBell" />
          </button>
          <Icon name="profileOutline" onClick={() => navigate('/dashboard/profile')} />
        </div>
      </div>
      <div className="flightrooms-header-search">
        <SearchField search={searchQuery} setSearch={setSearchQuery} />
      </div>
      {!searchQuery ? (
        <div className="flightrooms-header-nav-container">
          <TabBar initialTab={selectedFlightroomTab === TRoomArrivalStatus.Ongoing ? 0 : 1} tabs={tabs} />
        </div>
      ) : null}
    </header>
  );
};
