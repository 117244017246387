import { useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Checkbox, FlexComponent, Text } from '@aviobook/cms/_shared';
import { createColumnHelper, getCoreRowModel, useReactTable } from '@tanstack/react-table';
import { TTag } from 'types/src/tag';

import { useDeleteTags } from '../_queries/useDeleteChatbotTags';
import { useTags } from '../_queries/useTags';
import { useUpdateTags } from '../_queries/useUpdateTags';

export const useChatbotTagsContentTable = () => {
  const { t } = useTranslation();
  const { mutate: createTag, isLoading: isLoadingCreateTag } = useUpdateTags();
  const { data: tags, isLoading, refetch: refetchTags } = useTags();

  const { deleteSelectedTags, isLoading: isLoadingDeleteTags } = useDeleteTags();
  const columnHelper = createColumnHelper<TTag>();

  const [itemsToDelete, setItemsToDelete] = useState<string[]>([]);
  const isRowSelected = (id: string) => {
    return itemsToDelete.find(item => item === id)?.length > 0;
  };
  const selectItem = (id: string, value) => {
    if (value) {
      setItemsToDelete([...itemsToDelete, id]);
    } else {
      setItemsToDelete(itemsToDelete.filter(item => item !== id));
    }
  };

  const isCheckedAll = () => {
    return tags.every(item => itemsToDelete.find(i => i === item.id));
  };
  const setSelectAll = (checked: boolean) => {
    let updatedItems = [];
    if (checked) {
      updatedItems = data.map(item => item.id);
    }
    setItemsToDelete(updatedItems);
  };

  const data = useMemo(() => {
    if (!tags) return [];
    return tags.sort((a, b) => a.text.localeCompare(b.text));
  }, [tags]);

  const columns = [
    columnHelper.accessor('text', {
      cell: info => (
        <FlexComponent flexDirection="row">
          <Checkbox
            checked={isRowSelected(info.row.original.id)}
            name="tag"
            onChange={value => selectItem(info.row.original.id, value)}
          />
          <Text as="span" color="aviobookCMSLightBlue" size="MD" width="partial-width">
            {info.getValue()}
          </Text>
        </FlexComponent>
      ),
      header: () => (
        <FlexComponent backgroundColor="transparent" flexDirection="row">
          <Checkbox checked={isCheckedAll()} name="tags" onChange={setSelectAll} />
          <Text as="span" color="aviobookLightPrimaryAlpha" size="XS" uppercase weight="bold" width="partial-width">
            {t(tags.length > 1 ? 'SHARED.TEXT.QUICKREPLIES.MULTI' : 'SHARED.TEXT.QUICKREPLIES.SINGLE')}
          </Text>
        </FlexComponent>
      ),
      id: 'KEYWORDS',
      size: 10,
    }),
    columnHelper.accessor('description', {
      cell: info => (
        <Text as="span" color="aviobookCMSLightBlue" size="MD" width="partial-width">
          {info.getValue()}
        </Text>
      ),
      id: 'Description',
    }),
  ];

  const table = useReactTable({
    columns,
    data,
    getCoreRowModel: getCoreRowModel(),
  });

  return {
    createTag,
    deleteTags: async () => {
      await deleteSelectedTags(itemsToDelete);
      await refetchTags();
    },
    isLoading,
    isLoadingCreateTag,
    isLoadingDeleteTags,
    itemsToDelete,
    table,
  };
};
