import { FC } from 'react';
import { useTranslation } from 'react-i18next';
import { Icon, Text } from '@aviobook/web/_shared';
import classNames from 'classnames';

import './pendingMessageWrapper.scss';

type TProps = {
  children: React.ReactNode;
  isPending?: boolean;
  nextMessageBySameUser?: boolean;
  onClickDelete: () => void;
  onClickRetry: () => void;
};

export const PendingMessageWrapper: FC<TProps> = ({
  children,
  onClickDelete,
  nextMessageBySameUser,
  onClickRetry,
  isPending,
}) => {
  const { t } = useTranslation();
  const sharedClassnames = classNames('global-message-container', { 'next-message-by-same-user': nextMessageBySameUser });

  if (!isPending) return <div className={sharedClassnames}>{children}</div>;

  return (
    <div className={classNames('pending-message-container', sharedClassnames)}>
      <div className="pending-message-content-container">
        {children}
        <Icon name="triangleCoreCautionFilled" size={2.5} />
      </div>
      <Text as="span" className="pending-message-retry-text" color="aviobookWebSemanticsCautionCaution10" size="XS">
        <>
          {t('MESSAGE.SEND.FAILED.TEXT')}{' '}
          <button onClick={onClickRetry}>
            <Text as="span" color="aviobookWebSemanticsCautionCaution10" size="XS" weight="bold">
              {t('SHARED.BUTTONS.RETRY')}
            </Text>
          </button>{' '}
          {t('GENERAL.OR')}{' '}
          <button onClick={onClickDelete}>
            <Text as="span" color="aviobookWebSemanticsCautionCaution10" size="XS" weight="bold">
              {t('SHARED.BUTTONS.DELETE')}
            </Text>
          </button>
          .
        </>
      </Text>
    </div>
  );
};
