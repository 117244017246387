import { FC, useState } from 'react';
import { useTranslation } from 'react-i18next';
import Modal from 'react-modal';
import { AddButton, EmptyState, Spinner, TableLayout } from '@aviobook/cms/_shared';
import { ConfirmationModal } from '@aviobook/cms/_shared/confirmationModal/ConfirmationModal';
import { customModalStyles } from '@aviobook/cms/_shared/modal/ModalUtils';
import { useAuthenticateCMS } from '@aviobook/cms/auth/_queries';

import { useChatbotTagsContentTable } from './_hooks/useChatbotTagsContentTable';
import { AddTagModal } from './AddTagModal';

import './chatbotTags.scss';

export const ChatbotTags: FC = () => {
  const [isOpenAddModal, setOpenAddModal] = useState(false);
  const [isOpenDeleteModal, setOpenDeleteModal] = useState(false);
  const { t } = useTranslation();
  const { hasPermission } = useAuthenticateCMS();
  const { table, isLoading, itemsToDelete, createTag, deleteTags, isLoadingDeleteTags, isLoadingCreateTag } =
    useChatbotTagsContentTable();

  if (isLoading) return <Spinner />;

  return (
    <div>
      <Modal
        isOpen={isOpenAddModal || isOpenDeleteModal}
        onRequestClose={() => (isOpenAddModal ? setOpenAddModal(false) : setOpenDeleteModal(false))}
        style={customModalStyles}
      >
        {isOpenAddModal ? (
          <AddTagModal
            closeModal={() => setOpenAddModal(false)}
            isLoading={isLoadingCreateTag}
            onAdd={async values => {
              await createTag(values);
              setOpenAddModal(false);
            }}
          />
        ) : null}
        {isOpenDeleteModal ? (
          <ConfirmationModal
            cancelButtonAction={() => setOpenDeleteModal(false)}
            cancelButtonLabel={'CANCEL'}
            confirmButtonAction={async () => {
              await deleteTags();
              setOpenDeleteModal(false);
            }}
            confirmButtonLabel={'Delete'}
            loading={isLoadingDeleteTags}
            title={`Delete quick ${itemsToDelete?.length > 1 ? 'replies' : 'reply'}?`}
          />
        ) : null}
      </Modal>

      <TableLayout
        addButtonAction={() => setOpenAddModal(true)}
        addButtonLabel={t('QUICK_REPLIES_ADDBUTTON')}
        deleteButtonAction={() => setOpenDeleteModal(true)}
        deleteButtonDisabled={itemsToDelete?.length <= 0}
        emptyState={() => (
          <EmptyState
            button={() =>
              hasPermission('ADD_TAGS') ? (
                <AddButton
                  label={t('QUICK_REPLIES_ADDBUTTON')}
                  onClick={() => {
                    setOpenAddModal(true);
                  }}
                />
              ) : null
            }
            description={t('QUICK_REPLIES_EMPTYSTATE_DESCRIPTION')}
            title={t('QUICK_REPLIES_EMPTYSTATE_TITLE')}
          />
        )}
        isAddButtonPresent={hasPermission('ADD_TAGS')}
        isDeleteButtonPresent={hasPermission('DELETE_TAGS')}
        table={table}
      />
    </div>
  );
};
