import { FC, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Button, Checkbox, Spinner, Text } from '@aviobook/web/_shared';
import { TRequest } from 'types';

import Modal from '../Modal';
import { ModalOpener } from '../ModalOpener';
import { usePostGrantAccess } from './_queries/usePostGrantAccess';

import './userRequestAccessModal.scss';

type TProps = {
  flightroomName: string;
  onSuccess: () => void;
  requests: TRequest[];
  roomId: string;
};

export const UserRequestAccessModal: FC<TProps> = ({ requests, roomId, flightroomName, onSuccess }) => {
  const { t } = useTranslation();
  const [selectedUserIds, setSelectedUserIds] = useState<string[]>([]);
  const { mutateAsync: grantAccess, isLoading: isLoadingAccess, isSuccess: isSuccessGrantAccess } = usePostGrantAccess();
  const checkedCount = selectedUserIds.length;

  const handleCheckboxChange = (userId: string) => {
    setSelectedUserIds(prevSelectedUserIds => {
      if (prevSelectedUserIds.includes(userId)) {
        return prevSelectedUserIds.filter(user => user !== userId);
      } else {
        return [...prevSelectedUserIds, userId];
      }
    });
  };

  const handleSelectAll = () => {
    if (selectedUserIds.length === requests.length) {
      setSelectedUserIds([]);
    } else {
      const allUserIds = requests.map(request => request.userId);
      setSelectedUserIds(allUserIds);
    }
  };

  const handleAccessPermission = async (hasBeenGrantedAccess: boolean) => {
    await grantAccess({
      hasBeenGrantedAccess,
      roomId,
      userIds: selectedUserIds,
    });
  };

  useEffect(() => {
    if (isSuccessGrantAccess) onSuccess();
  }, [isSuccessGrantAccess]);

  if (isLoadingAccess) return <Spinner overlay theme="inversed" />;

  return (
    <Modal>
      <Modal.Header>
        {t(
          requests?.length > 1 ? 'FLIGHTROOMS.USER_REQUESTS_ACCESS.MODAL.TITLE' : 'FLIGHTROOMS.USER_REQUEST_ACCESS.MODAL.TITLE',
          { amount: requests?.length, flightroomName },
        )}
      </Modal.Header>
      <div className="flightroom-request-modal-center">
        <a className="flightroom-request-modal-select-all" onClick={handleSelectAll} type="button">
          <Text color="aviobookWebAccentRomeo07" size="LG" weight="bold">
            {t(
              selectedUserIds.length === requests.length
                ? 'FLIGHTROOMS.USER_REQUEST_ACCESS.MODAL.DESELECT'
                : 'FLIGHTROOMS.USER_REQUEST_ACCESS.MODAL.SELECT',
            )}
          </Text>
        </a>
        {requests?.map(request => (
          <div className="flightroom-request-modal-content" key={request.userId}>
            <Checkbox
              checked={selectedUserIds.includes(request.userId)}
              className="flightroom-request-checkbox"
              name={t('APPROVE_ACCESS.ALERT.TITLE', { name: request.userInfo })}
              onChange={() => handleCheckboxChange(request.userId)}
            />
            <Text color="aviobookDarkSecondaryHotel" size="SM">
              {request.userInfo}
            </Text>
          </div>
        ))}
      </div>
      <Modal.Buttons>
        <div className="buttons-left-container">
          <Button
            disabled={checkedCount === 0 || isLoadingAccess}
            isOutline
            onClick={() => handleAccessPermission(false)}
            theme="negative"
          >
            {t('APPROVE_ACCESS.SHEET.DENY')}
          </Button>
        </div>
        <Button isOutline onClick={() => ModalOpener.instance.close()} theme="dark">
          {t('SHARED.BUTTONS.CANCEL')}
        </Button>
        <Button disabled={checkedCount === 0 || isLoadingAccess} onClick={() => handleAccessPermission(true)} theme="primary">
          {t('APPROVE_ACCESS.SHEET.APPROVE')}
        </Button>
      </Modal.Buttons>
    </Modal>
  );
};
