import { FC, useState } from 'react';
import { useTranslation } from 'react-i18next';
import Modal from 'react-modal';
import { AddButton, EmptyState, Spinner, TableLayout } from '@aviobook/cms/_shared';
import { ConfirmationModal } from '@aviobook/cms/_shared/confirmationModal/ConfirmationModal';
import { customModalStyles } from '@aviobook/cms/_shared/modal/ModalUtils';
import { AddRoleTagsModal } from '@aviobook/cms/connect/_screens/roles/_components';

import { useRoleTagContentTable } from './_hooks';

import './roleTags.scss';

export const RoleTags: FC = () => {
  const { t } = useTranslation();
  const { table, isLoading, itemsToDelete, createTag, deleteTags, isMutateLoading } = useRoleTagContentTable();
  const [isOpenAddModal, setOpenAddModal] = useState(false);
  const [isOpenDeleteModal, setOpenDeleteModal] = useState(false);

  if (isLoading) return <Spinner />;

  return (
    <>
      <Modal
        contentLabel="Example Modal"
        isOpen={isOpenAddModal || isOpenDeleteModal}
        onRequestClose={() => (isOpenAddModal ? setOpenAddModal(false) : setOpenDeleteModal(false))}
        style={customModalStyles}
      >
        {isOpenAddModal ? (
          <AddRoleTagsModal
            closeModal={() => setOpenAddModal(false)}
            isLoading={isMutateLoading}
            onAdd={async values => {
              await createTag(values);
              setOpenAddModal(false);
            }}
          />
        ) : null}
        {isOpenDeleteModal ? (
          <ConfirmationModal
            cancelButtonAction={() => setOpenDeleteModal(false)}
            cancelButtonLabel={'CANCEL'}
            confirmButtonAction={async () => {
              await deleteTags();
              setOpenDeleteModal(false);
            }}
            confirmButtonLabel={'Delete'}
            loading={isMutateLoading}
            title={`Delete quick ${itemsToDelete?.length > 1 ? 'replies' : 'reply'}?`}
          />
        ) : null}
      </Modal>
      <TableLayout
        addButtonAction={() => setOpenAddModal(true)}
        addButtonLabel={t('QUICK_REPLIES_ADDBUTTON')}
        deleteButtonAction={() => setOpenDeleteModal(true)}
        deleteButtonDisabled={itemsToDelete?.length <= 0}
        emptyState={() => (
          <EmptyState
            button={() => <AddButton label={t('QUICK_REPLIES_ADDBUTTON')} onClick={() => setOpenAddModal(true)} />}
            description={t('QUICK_REPLIES_EMPTYSTATE_DESCRIPTION')}
            title={t('QUICK_REPLIES_EMPTYSTATE_TITLE')}
          />
        )}
        isAddButtonPresent={true}
        isDeleteButtonPresent={true}
        table={table}
      />
    </>
  );
};
