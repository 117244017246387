import { useState } from 'react';
import { TRoomArrivalStatus } from '@aviobook/_http';
import { useNetworkInfo } from '@aviobook/web/_hooks';
import { FlightRoomList } from '@aviobook/web/flightrooms/_components/FlightRoomList';
import { FlightRoomsHeaderComponent } from '@aviobook/web/flightrooms/_components/FlightRoomsHeaderComponent/FlightRoomsHeaderComponent';
import { SearchedFlightRoomList } from '@aviobook/web/flightrooms/_components/SearchedFlightRoomList';

import './flightrooms.scss';

export const Flightrooms = () => {
  const [selectedFlightroomTab, setSelectedFlightroomTab] = useState<TRoomArrivalStatus.Ongoing | TRoomArrivalStatus.Completed>(
    TRoomArrivalStatus.Ongoing,
  );
  const [searchQuery, setSearchQuery] = useState<string>('');

  const { isOnline } = useNetworkInfo();

  return (
    <aside className="flightrooms-container">
      <FlightRoomsHeaderComponent
        isOnline={isOnline}
        searchQuery={searchQuery}
        selectedFlightroomTab={selectedFlightroomTab}
        setSearchQuery={setSearchQuery}
        setSelectedFlightroomTab={setSelectedFlightroomTab}
      />
      {searchQuery ? (
        <SearchedFlightRoomList searchQuery={searchQuery} />
      ) : (
        <FlightRoomList selectedFlightroomTab={selectedFlightroomTab} />
      )}
    </aside>
  );
};
