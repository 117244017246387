import { FC } from 'react';
import { Outlet } from 'react-router-dom';
import { Chat } from '@aviobook/web/chat/Chat';
import { Empty } from '@aviobook/web/empty/Empty';
import { useFlightroomsContext } from 'shared';

import './authorizedLayout.scss';

// TODO: rename to DashboardLayout
export const AuthorizedLayout: FC = () => {
  const { selectedRoom } = useFlightroomsContext();
  return (
    <main className="web-authorized-layout">
      <Outlet />
      {selectedRoom ? <Chat room={selectedRoom} /> : <Empty />}
    </main>
  );
};
